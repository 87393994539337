import { MapTypeStyle } from 'google-map-react';

// Source:
// https://mapstyle.withgoogle.com/
// With adaptions
export const MapStyleOptions: MapTypeStyle[] = [
    {
        stylers: [
            {
                saturation: -70
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e0eeec'
            },
            {
                lightness: 30
            }
        ]
    }
];
