export default class CompanyFieldHider {
    constructor() {
        this.registerFields();
    }

    registerFields() {
        $('[data-company-toggle]').off('change');
        $('[data-company-toggle]').on('change', () => {
            this.updateFieldVisability();
        });
        this.updateFieldVisability();
    }

    updateFieldVisability() {
        const toggle = (document.querySelector('[data-company-toggle]') as HTMLInputElement);
        const companyContainer = $('[data-company-fields-container]');
        const userContainer = $('[data-user-fields-container]');
        if (toggle) {
            if (toggle.checked) {
                companyContainer.show();
                userContainer.hide();
            } else {
                companyContainer.hide();
                userContainer.show();
            }
        }
    }
}
