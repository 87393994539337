import React, { useState } from 'react';
import VillageSearchField from './shared/VillageSearchField';
import Village from '../interfaces/village';

interface HomeSearchFieldProps {
    initialZipCode: string;
    initialName: string;
}

export default function({
    initialZipCode,
    initialName
}: HomeSearchFieldProps) {
    const [village, setVillage] = useState<Village>({zipCode: initialZipCode, name: initialName});

    return (
        <div className="listings-search">
            <div className="d-block listing-form">
                <VillageSearchField
                    initialSearchText={`${initialZipCode} ${initialName}`}
                    onSelect={setVillage}
                    placeholderText={null}
                />
            </div>
            <input value={village.zipCode} readOnly name="zip" id="zip" className="d-none" />
            <input value={village.name} readOnly name="city" id="city" className="d-none" />
        </div>
    );
}
