// NOTE: Please use Bootstraps visbility toggles for radios and checkboxes
// ---------------------------------------------------------------------------------
// this class manages visibility toggles for selects.
// a visibility toggle is an element that triggers the visibility of another one
// assign the attribute data-toggle-fields="selector" to a select element to make it a toggle.
// assign the attribute data-toggle="true" to the options that should make the target elements visible.
export default class VisibilityToggle {
    constructor() {
        this.selectToggles();
    }

    selectToggles() {
        const selectToggles = $('select[data-toggle-fields]');
        selectToggles.on('change', (event) => {
            const selectField = event.currentTarget as HTMLSelectElement;
            const selectedOption = selectField.options[selectField.selectedIndex];
            const toggle = selectedOption.dataset.toggle === 'true';
            const toggleTarget = selectField.dataset.toggleFields;
            $(toggleTarget).toggle(toggle);
        });
        selectToggles.trigger('change');
    }
}
