import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PaymentMethodRadioGroup from 'src/javascripts/components/bookings/PaymentMethodRadioGroup';
import { PaymentMethod } from 'src/javascripts/interfaces/payment';
import { Stripe, StripeError } from '@stripe/stripe-js';

export interface CheckoutFormProps {
    paymentMethods: PaymentMethod[];
    selectedPaymentMethod: PaymentMethod;
    email: string;
    cashInformationLabel: string;
}

export default function CheckoutForm({paymentMethods, selectedPaymentMethod, cashInformationLabel, email}: CheckoutFormProps) {
    const stripe: Stripe = useStripe();
    const elements = useElements();
    const [paymentMethod, setPaymentMethod] = useState(selectedPaymentMethod);
    const [token, setToken] = useState('');
    const [stripeError, setStripeError] = useState<StripeError>(null);

    const isSelectedPaymentMethod = (selectedPaymentMethod) => {
        return paymentMethod === selectedPaymentMethod;
    };

    const handleCardElementChange = async (event) => {
        if (event.complete) {
            // Wrong typing, this is possible:
            // https://github.com/stripe/react-stripe-elements/blame/8b5d1ffb2eaf5235193597b7d4f57349163a12ef/README.md#L225
            const {error, token} = await stripe.createToken({type: 'card', email} as any);
            if (error) {
                setStripeError(error);
            } else {
                setToken(token.id);
            }
        }
    };
    const StripeCardForm = <div className="row">
        <div className="col-11 col-lg-6">
            <div className="card p-2">
                <CardElement onChange={handleCardElementChange} options={{hidePostalCode: true}}/>
                {stripeError && <p className="alert alert-danger mt-3">
                    {stripeError.message}
                </p>}
            </div>
        </div>

        {token && <div className="col-1 col-lg-1">
            <span className="badge badge-success p-2 mt-1"><i className="far fa-check"></i></span>
        </div>}
        <input type="hidden" name="booking[stripe_card_token]" value={token}/>

    </div>;

    const PaymentInformation = <div className="alert alert-warning">{cashInformationLabel}</div>;

    return <div className="checkout">
        <PaymentMethodRadioGroup
            paymentMethods={paymentMethods}
            selectedPaymentMethod={paymentMethod}
            onChange={setPaymentMethod}
        />
        {isSelectedPaymentMethod('credit_card') && StripeCardForm}
        {isSelectedPaymentMethod('cash') && PaymentInformation}
    </div>;
}
