import 'bootstrap/dist/js/bootstrap';
import 'jquery-ui-dist/jquery-ui';
import jQuery from 'jquery';
import moment from 'moment';
import 'photoswipe/dist/photoswipe';
import 'busy-load'
import * as PhotoSwipe from 'photoswipe/dist/photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import Rails from '@rails/ujs';
import React from 'react';
import { OPEN_DOWN } from 'react-dates/constants';
import ReactDOM from 'react-dom';
import 'src/javascripts/accommodation_search';
import 'src/javascripts/accommodations';
import 'src/javascripts/phone_number_verification';
import 'src/javascripts/tooltipper';
import 'src/javascripts/controllers';
import LivingSearch from 'src/javascripts/components/accommodations/LivingSearch';
import HomeSearchField from 'src/javascripts/components/HomeSearchField';
import AccommodationMap from 'src/javascripts/components/accommodations/AccommodationMap';
import AccommodationsSearch from 'src/javascripts/components/accommodations/AccommodationsSearch';
import ListingsSearch from 'src/javascripts/components/marketplace/ListingsSearch';
import UserCityInputField from 'src/javascripts/components/users/UserCityInputField';
import ListingSearchField from 'src/javascripts/components/marketplace/ListingSearchField';
import AllocationDatePicker from 'src/javascripts/components/allocations/AllocationDatePicker';
import TimeShareTransactionHandler from 'src/javascripts/time_share_transaction_handler';
import Gallery from 'src/javascripts/gallery';
import ContactFormValidator from 'src/javascripts/contact_form_validator';
import LoadingIndicator from 'src/javascripts/loader_indicator';
import MarketplaceCategories from 'src/javascripts/marketplace_categories';
import 'src/javascripts/popup_share';
import 'src/javascripts/recaptcha';
import 'src/javascripts/scroll_into_view';
import 'src/javascripts/channel_dispatcher';
import 'src/javascripts/marketplace_categories';
import "src/javascripts/transaction_modal";
import VisibilityToggle from 'src/javascripts/visibility_toggle';
import CompanyFieldHider from 'src/javascripts/company_field_hider';
import InputLengthValidator from 'src/javascripts/input_length_validator';
import FormChangeListener from 'src/javascripts/form_change_listener';
import Turbolinks from 'turbolinks';
import Checkout from 'src/javascripts/components/bookings/Checkout';
import ProfilePictureUpload from "../src/javascripts/profile_picture_upload";
import Dropzone from "dropzone/dist/min/dropzone.min";

require.context('../images', true);

window.$ = jQuery;
window.PhotoSwipe = PhotoSwipe;
window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;
window.notifyMobileApplication = (message) => {
    if(window?.flutter_inappwebview?.callHandler != null && window?.flutter_inappwebview?.callHandler != undefined) {
        window?.flutter_inappwebview?.callHandler('MobileActor', message);
    }
};

Rails.start();
Turbolinks.start();
Dropzone.autoDiscover = false;

function initAccommodationCalendar() {
    const allocationCalendarPlaceholder = document.querySelector('[data-allocation-calendar]');

    if (allocationCalendarPlaceholder != null) {
        const startDatePlaceholderText = allocationCalendarPlaceholder.dataset.startDatePlaceholderText;
        const endDatePlaceholderText = allocationCalendarPlaceholder.dataset.endDatePlaceholderText;

        ReactDOM.render(
            <AllocationDatePicker
                accommodationId={parseInt(allocationCalendarPlaceholder.dataset.accommodationId)}
                allocationType={allocationCalendarPlaceholder.dataset.allocationType}
                displayFormat={One11.i18n.datepicker.format}
                startDatePlaceholderText={startDatePlaceholderText || One11.i18n.datepicker.start_date}
                endDatePlaceholderText={endDatePlaceholderText || One11.i18n.datepicker.end_date}
                indefiniteStayLabelText={allocationCalendarPlaceholder.dataset.indefiniteStayLabelText}
                maxYears={One11.datePicker.max_years}
                openDirection={allocationCalendarPlaceholder.dataset.openDirection || OPEN_DOWN}
            />,
            allocationCalendarPlaceholder)
    }
}

function initAccommodationMap() {
    const accommodationMap = document.querySelector('[data-accommodation-map]');

    if (accommodationMap != null) {
        ReactDOM.render(
            <AccommodationMap
                accommodationLatitude={accommodationMap.dataset.accommodationLatitude}
                accommodationLongitude={accommodationMap.dataset.accommodationLongitude}
                accommodationTitle={accommodationMap.dataset.accommodationTitle}
                communityLatitude={accommodationMap.dataset.communityLatitude}
                communityLongitude={accommodationMap.dataset.communityLongitude}
                communityTitle={accommodationMap.dataset.communityTitle}
            />,
            accommodationMap
        );
    }
}

function initAccommodationForm() {
    const accommodationTabs = document.querySelector('[data-accommodation-tabs]');
    if (accommodationTabs != null) {
        const accommodationForm = document.querySelector('[data-accommodation-form]');
        new FormChangeListener(accommodationForm, accommodationForm.dataset.confirmMessage);
    }
}

function initTimeShareTransactionHandler() {
    window.timeShareTransactionHandler = new TimeShareTransactionHandler();
    window.timeShareTransactionHandler.register();
}

const providedComponents = {
    AccommodationsSearch: AccommodationsSearch,
    LivingSearch: LivingSearch,
    HomeSearchField: HomeSearchField,
    Checkout: Checkout,
    ListingsSearch: ListingsSearch,
    UserCityInputField: UserCityInputField,
    ListingSearchField: ListingSearchField
};

function initReactComponents() {
    document.querySelectorAll('[data-react-component]').forEach((placeholder) => {
        const componentName = placeholder.dataset.name;
        const props = JSON.parse(placeholder.dataset.props);
        ReactDOM.render(React.createElement(providedComponents[componentName], props), placeholder);
    });
}

function initInputLengthValidator() {
    $('.inline-validatable').each((_i, validatable) => { new InputLengthValidator(validatable) });
}

document.addEventListener('turbolinks:load', () => {
    moment.locale(One11.shortLocale);
    new VisibilityToggle();
    new CompanyFieldHider();
    new LoadingIndicator();
    new Gallery();
    new ContactFormValidator();
    new MarketplaceCategories();
    new ProfilePictureUpload();

    initReactComponents();
    initInputLengthValidator();

    //TODO: replace these initializers, and use the generic one
    initAccommodationCalendar();
    initAccommodationMap();
    initAccommodationForm();
    initTimeShareTransactionHandler();

    Rails.refreshCSRFTokens();
});
