import React from 'react';
import Filters, { FiltersTranslations } from 'src/javascripts/interfaces/filters';

interface AccommodationsSearchFiltersProps {
    filtersTranslations: FiltersTranslations;
    filters: Filters;
    onChange: (filters: Filters) => void;
}

export default function AccommodationsSearchFilters({filtersTranslations, filters, onChange}: AccommodationsSearchFiltersProps) {
    return <div className="dropdown h-100">
        <div aria-haspopup="true"
             aria-expanded="true"
             className="btn align-items-center btn-secondary d-flex h-100 justify-content-center dropdown-toggle"
             data-toggle="dropdown"
             id="filter-dropdown-button">
            {filtersTranslations.buttonName}
        </div>
        <div aria-labelledby="filter-dropdown-button"
             // `filter-dropdown-menu` depends on app/webpacker/src/javascripts/accommodation_search.ts
             // to avoid closing the dropdown. See this file for further explanation
             className="dropdown-menu dropdown-menu-right filter-dropdown-menu w-100">
            <div className="form-check mx-3 mb-3">
                <input
                       id="only_barrier_free"
                       className="boolean form-check-input"
                       // The `!!` fixes this: https://github.com/facebook/react/issues/6779
                       type="checkbox" checked={!!filters.onlyBarrierFree}
                       onChange={(event) => onChange({...filters, onlyBarrierFree: event.target.checked})}/>
                <label className="boolean form-check-label"
                       htmlFor="only_barrier_free">{filtersTranslations.onlyBarrierFree}</label>
            </div>
            <div className="form-check mx-3 mb-3">
                <input
                       id="only_furnished"
                       className="boolean form-check-input"
                       type="checkbox" checked={!!filters.onlyFurnished}
                       onChange={(event) => onChange({...filters, onlyFurnished: event.target.checked})}/>
                <label className="boolean form-check-label"
                       htmlFor="only_furnished">{filtersTranslations.onlyFurnished}</label>
            </div>
            <div className="form-check mx-3">
                <input
                       id="only_with_assisted_living"
                       className="boolean form-check-input"
                       type="checkbox" checked={!!filters.onlyWithAssistedLiving}
                       onChange={(event) => onChange({...filters, onlyWithAssistedLiving: event.target.checked})}/>
                <label className="boolean form-check-label"
                       htmlFor="only_with_assisted_living">{filtersTranslations.onlyWithAssistedLiving}</label>
            </div>
        </div>
    </div>;
}
