declare var PhotoSwipe;
declare var PhotoSwipeUI_Default;

export default class Gallery {
    private allImages = [];

    constructor() {
        if ($('.gallery').length > 0) {
            this.initGalleries();
            this.registerClickListener();
        }
    }

    initGalleries() {
        $('.gallery').each((index, gallery) => {
            this.initGalleryItems(index, gallery);
        });
}

    registerClickListener() {
        $('.gallery a').on('click', event => {
            event.preventDefault();
            const galleryItem = $(event.currentTarget);
            const galleryIndex = $('.gallery').index(galleryItem.parents('.gallery'));
            const $pswp = $('.pswp')[galleryIndex];
            const galleryImages = this.allImages[galleryIndex];
            const options = this.galleryOptions(galleryItem);
            new PhotoSwipe($pswp, PhotoSwipeUI_Default, galleryImages, options).init();
        });
    }

    initGalleryItems(index, gallery) {
        const galleryContainer = this.allImages[index] = [];
        $(gallery).find('a').each((_, galleryItem) => {
            galleryContainer.push(this.galleryItemProperties($(galleryItem)));
        });
    }

    galleryItemProperties(galleryItem) {
        return {
            src: galleryItem.attr('href'),
            w: galleryItem.data('width'),
            h: galleryItem.data('height'),
            title: galleryItem.data('caption')
        };
    }

    galleryOptions(galleryItem) {
        return {
            index: galleryItem.parents('.gallery').find('a').index(galleryItem),
            bgOpacity: 0.85,
            showHideOpacity: true
        };
    }
}
