import GoogleMapReact from 'google-map-react';
import React from 'react';
import { MapStyleOptions } from './map_style_options';

interface AccommodationMapProps {
    accommodationLatitude: string;
    accommodationLongitude: string;
    accommodationTitle: string;
    communityLatitude: string;
    communityLongitude: string;
    communityTitle: string;
}

function Marker({className, markerIcon, title}: { lat: number, lng: number, className: string,
                                                  markerIcon: string, title: string }) {
    return <div data-toggle="tooltip" title={title}
                className={`marker ${className}`}>
        <i className={markerIcon} />
    </div>;
}

export default function AccommodationMap(props: AccommodationMapProps) {
    const communityPresent = !!(props.communityLatitude && props.communityLongitude);
    const accommodationLatitude = Number(props.accommodationLatitude);
    const accommodationLongitude = Number(props.accommodationLongitude);
    const communityLatitude = Number(props.communityLatitude);
    const communityLongitude = Number(props.communityLongitude);
    const centerMap = ({map, maps}) => {
        const bounds = new maps.LatLngBounds();

        bounds.extend({lat: accommodationLatitude, lng: accommodationLongitude});
        if (communityPresent) { bounds.extend({lat: communityLatitude, lng: communityLongitude}); }

        map.fitBounds(bounds, 50);
    };

    return (
        <div className="interactive-map">
            <GoogleMapReact
                bootstrapURLKeys={{key: One11.googleMapsKey}}
                defaultCenter={{lat: 46.8182, lng: 8.2275}}
                defaultZoom={13}
                options={ {styles: MapStyleOptions }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={centerMap}
            >
                <Marker
                    lat={accommodationLatitude}
                    lng={accommodationLongitude}
                    className={'accommodation-marker'}
                    markerIcon={'far fa-bed fa-fw'}
                    title={props.accommodationTitle}
                />
                {
                    communityPresent &&
                    <Marker
                        lat={communityLatitude}
                        lng={communityLongitude}
                        className={'community-marker'}
                        markerIcon={'fab fa-hubspot'}
                        title={props.communityTitle}
                    />
                }
            </GoogleMapReact>
        </div>
    );
}
