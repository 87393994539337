import moment from 'moment';

enum DateStringBrand { _ = '' }

export type DateString = string & DateStringBrand;

function checkValidDateString(str: string): str is DateString {
    return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

export function toDateString(date: Date | moment.Moment | string): DateString {
    const dateString = moment(date).format('YYYY-MM-DD');
    /* istanbul ignore next */
    // the code is needed for typescript but is never reached.
    // see also https://spin.atomicobject.com/2017/06/19/strongly-typed-date-string-typescript/
    if (checkValidDateString(dateString)) {
        return dateString;
    }
}
