import { Bounds } from 'google-map-react';
import Rails from '@rails/ujs';
import Accommodation, { RawAccommodation } from 'src/javascripts/interfaces/accommodation';
import Filters from 'src/javascripts/interfaces/filters';
import Village, { RawVillage } from 'src/javascripts/interfaces/village';

export default class AccommodationApi {
    static search(bounds: Bounds, filters: Filters): Promise<Accommodation[]> {
        const {sw: southwest, ne: northeast} = bounds;

        return fetch('/accommodations/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': Rails.csrfToken()
            },
            body: JSON.stringify({
                locale: One11.locale,
                search: {
                    southwest_latitude: southwest.lat,
                    southwest_longitude: southwest.lng,
                    northeast_latitude: northeast.lat,
                    northeast_longitude: northeast.lng,
                    only_barrier_free: filters.onlyBarrierFree,
                    only_furnished: filters.onlyFurnished,
                    only_with_assisted_living: filters.onlyWithAssistedLiving
                }
            })
        }).then(res => res.json()).then(raw => AccommodationApi.transformAccommodations(raw.data));
    }

    static villages(): Promise<Village[]> {
        return fetch('/villages').then(res => res.json()).then(raw => AccommodationApi.transformVillages(raw));
    }

    private static transformAccommodations = (accommodations: RawAccommodation[]): Accommodation[] =>
        accommodations.map(({id, attributes: {
                barrierFree, city, formattedLongStayPrice, formattedShortStayPrice, furnished, coordinates,
                name, numberOfRooms, pictures, translatedCategory, urlPath, qualitySealActive, zip
            }}) =>
            ({
                id: Number(id),
                barrierFree,
                city,
                formattedLongStayPrice: Number(formattedLongStayPrice),
                formattedShortStayPrice: Number(formattedShortStayPrice),
                furnished,
                coordinates: {
                    latitude: Number(coordinates.latitude),
                    longitude: Number(coordinates.longitude)
                },
                name,
                numberOfRooms: Number(numberOfRooms),
                pictures,
                translatedCategory,
                urlPath,
                qualitySealActive,
                zip
            })
        )

    private static transformVillages = (villages: RawVillage[]): Village[] =>
        villages.map(([name, zipCode, latitude, longitude], id) =>
            ({id, name, zipCode: '' + zipCode, latitude, longitude})
        )
}
