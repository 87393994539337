import $ from 'jquery';
import Dropzone from 'dropzone/dist/min/dropzone.min';
import 'jquery-cropper/dist/jquery-cropper.min';
import { DropzoneOptions } from 'dropzone';
import Cropper from 'cropperjs';

interface ImageCropperOptions {
    selector: string;
    formSelector: string;
    imageUrl: string;
    uploadSelector: string;
    acceptedFileFormats: string;
    onSuccess: (html: HTMLHtmlElement) => void;
}

export default class ImageCropper {
    private readonly selector: string;
    private readonly formSelector: string;
    private readonly imageUrl: string;
    private readonly uploadSelector: string;
    private readonly acceptedFileFormats: string;
    private readonly onSuccess: (html: HTMLHtmlElement) => void;

    constructor({
                    selector,
                    formSelector,
                    imageUrl,
                    uploadSelector,
                    acceptedFileFormats,
                    onSuccess
                }: ImageCropperOptions) {
        this.selector = selector;
        this.formSelector = formSelector;
        this.imageUrl = imageUrl;
        this.uploadSelector = uploadSelector;
        this.acceptedFileFormats = acceptedFileFormats;
        this.onSuccess = onSuccess;
    }

    init() {
        if (!$(this.selector).length) {
            return;
        }
        const dropzone = new Dropzone(this.selector, this.getDropzoneOptions());

        $('.dz-default').append('<div class="default-upload-icon" data-default-upload-icon />');
        this.addDefaultIcon();
        this.registerListeners(dropzone);
    }

    private registerListeners(dropzone) {
        dropzone.on('maxfilesexceeded', (file) => {
            dropzone.removeAllFiles();
            dropzone.addFile(file);
            dropzone.removeEventListeners();
        });

        dropzone.on('removedfile', () => {
            $('.dropzone').removeClass('filled-dropzone');
            dropzone.setupEventListeners();
            this.addDefaultIcon();
        });

        dropzone.on('thumbnail', (file) => {
            this.onEditImage(file, dropzone);
            $('.dropzone').addClass('filled-dropzone');
        });

        this.onFormSubmission(dropzone);
    }

    onEditImage(file, dropzone) {
        if (file.cropped) {
            return;
        }
        $('.dz-preview').remove();
        const cachedFilename = file.name;
        const image = new Image();
        image.src = URL.createObjectURL(file);
        $('[data-profile-picture-upload-modal]').modal('hide');
        $('[data-image-cropper-modal]').modal('show');
        this.initCropper(image, cachedFilename, dropzone);
    }

    private getDropzoneOptions = (): DropzoneOptions => ({
        url: '#',
        autoProcessQueue: false,
        acceptedFiles: this.acceptedFileFormats,
        maxFiles: 1,
        thumbnailMethod: 'crop',
        thumbnailWidth: null,
        thumbnailHeight: null,
        addRemoveLinks: true,
        dictRemoveFile: One11.i18n.cropper.remove_file as string,
        dictDefaultMessage: One11.i18n.cropper.upload_file as string
    })

    private initCropper(image, cachedFilename, dropzone) {
        const imageContainer: JQuery<HTMLDivElement> = $("<div class='img-container'></div>");
        imageContainer.html(image);
        $('[data-image-cropper-modal-body]').html('').append(imageContainer);
        const cropper = new Cropper(image, {
            modal: false,
            background: false,
            aspectRatio: 1,
            viewMode: 2
        });
        $('[data-image-cropper-confirm]').off('click').on('click', () => {
            this.addFileToDropzone(dropzone, cropper.getCroppedCanvas({}), cachedFilename);
            $('[data-image-cropper-modal]').modal('hide');
            $('[data-profile-picture-upload-modal]').modal('show');
        });
    }

    private addFileToDropzone(dropzone, canvas: HTMLCanvasElement, cachedFilename) {
        dropzone.removeAllFiles();
        canvas.toBlob((blob) => {
            const newFile: any = blob; // DropzoneFiles have a readonly-name, but in this context it works
            newFile.cropped = true;
            newFile.name = cachedFilename;
            dropzone.addFile(newFile);
        });
    }

    private onFormSubmission(dropzone: Dropzone) {
        ($(this.formSelector) as JQuery<HTMLFormElement>).on('submit', (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const logo: Dropzone.DropzoneFile = dropzone.files[0];
            formData.append(
                this.uploadSelector,
                logo
            );
            $.ajax(e.target.action, {
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                success: ({html}) => {
                    this.onSuccess(html);
                    this.closeProfilePictureModal(dropzone);
                },
                complete: () => {
                    this.closeProfilePictureModal(dropzone);
                }
            });
        });
    }

    private closeProfilePictureModal(dropzone: Dropzone) {
        ($ as any).busyLoadFull('hide');
        $('[data-profile-picture-upload-modal]').modal('hide');
        $('[data-profile-picture-upload-modal-submit]').removeAttr('data-disable-with');
        dropzone.removeAllFiles();
    }

    private addDefaultIcon() {
        $('[data-default-upload-icon]').html('<br /><i class="fal fa-image fa-6x"/></div>');
    }
}
