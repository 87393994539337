import moment from 'moment';
import React from 'react';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';
import 'react-dates/initialize';
import { AllocationBaseDatePicker } from 'src/javascripts/components/allocations/AllocationBaseDatePicker';
import { AllocationSingleDatePickerProps } from 'src/javascripts/components/allocations/AllocationDatePicker';

interface SingleDatePickerState {
    focused: SingleDatePickerShape['focused'];
    date: SingleDatePickerShape['date'];
    firstAvailableDate: SingleDatePickerShape['date'];
}

export default class AllocationSingleDatePicker extends AllocationBaseDatePicker<AllocationSingleDatePickerProps, SingleDatePickerState> {
    /* istanbul ignore next */
    // https://github.com/gotwarlost/istanbul/issues/690
    constructor(props) {
        super(props);

        this.state = {
            focused: null,
            date: null,
            firstAvailableDate: null
        };
        this.isDayBlocked = this.isDayBlocked.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
    }

    isDayBlocked(day) {
        return this.state.firstAvailableDate > day;
    }

    componentDidMount() {
        const params = {'filter[type]': this.props.allocationType, 'filter[indefinite]': true};

        fetch(this.urlFor(params))
            .then(response => response.json())
            .then(data => {
                this.setState(prevState => ({
                    ...prevState,
                    firstAvailableDate: moment(data)
                }));
            });
    }

    scopedFieldAttribute(attribute) {
        return `${this.props.allocationType.toLowerCase()}[${attribute}]`;
    }

    render() {
        return (
            <SingleDatePicker
                date={this.state.date}
                id={this.scopedFieldAttribute('starts_on')}
                displayFormat={this.props.displayFormat}
                focused={this.state.focused}
                initialVisibleMonth={() => this.state.firstAvailableDate}
                isDayBlocked={this.isDayBlocked}
                isOutsideRange={this.isOutsideRange}
                numberOfMonths={1}
                openDirection={this.props.openDirection || OPEN_UP}
                onDateChange={date => this.setState({date})}
                onFocusChange={({focused}) => this.setState({focused})}
                placeholder={this.props.datePlaceholderText}
                showDefaultInputIcon={true}
            />
        );
    }
}
