export default class ContactFormValidator {
    constructor(){
        this.attachListener();
    }

    attachListener() {
        const forms = document.querySelectorAll('[data-contact-form]');
        forms.forEach((form) => {
            const inputs = Array.from(form.querySelectorAll('[data-contact-information-field]'));
            inputs.forEach(i => (i as any).required = true);

            const inputListener = e =>
            inputs
            .filter(i => i !== e.target)
            .forEach(i => ((i as any).required = !e.target.value.length));

            inputs.forEach(i => i.addEventListener('input', inputListener));
        });
    }
}
