import $ from 'jquery';
import ImageCropper from 'src/javascripts/image_cropper';

export default class ProfilePictureUpload {
    constructor() {
        if ($('[data-profile-picture-upload]').length > 0) {
            const upload: JQuery<HTMLFormElement> = $('[data-profile-picture-upload]');
            this.initImageCropper(upload);
        }
    }

    private initImageCropper(upload: JQuery<HTMLFormElement>) {
        new ImageCropper({
            selector: '[data-dropzone]',
            formSelector: '[data-profile-form]',
            imageUrl: upload.data('data-profile-picture-upload'),
            uploadSelector: 'user[profile_picture]',
            acceptedFileFormats: '.jpg,.png',
            onSuccess: (html) => {
                $('[data-profile-picture]').html(html);
            }
        }).init();
    }
}
