import React, { useState } from 'react';
import VillageSearchField from '../shared/VillageSearchField';
import Village from '../../interfaces/village';

interface ListingsSearchProps {
    initialZipCode: string;
    initialName: string;
    validState: string;
}

export default function({
    initialZipCode,
    initialName,
    validState
}: ListingsSearchProps) {
    const [village, setVillage] = useState<Village>({zipCode: initialZipCode, name: initialName});

    return (
        <div className="listings-search">
            <div className="d-block listing-form">
                <VillageSearchField
                    initialSearchText={initialZipCode && initialName ? `${initialZipCode} ${initialName}` : ''}
                    onSelect={setVillage}
                    onChange={(_newValue) => setVillage({zipCode: '', name: ''})}
                    placeholderText={null}
                    inputFieldClassName={`form-control ${validState}`}
                    suggestionsContainerClassName={'dropdown-menu-wrapper city-input-field-dropdown'}
                />
            </div>
              {village && (
                <>
                  <input value={village.zipCode} readOnly name="user[zip]" id="user_zip" className="d-none" />
                  <input value={village.name} readOnly name="user[city]" id="user_city" className="d-none" />
                </>
              )}
        </div>
    );
}
