function shareWindowOptions() {
    const height = 550;
    const width = 555;
    const left = window.innerWidth / 2 - (width / 2);
    const top = window.innerHeight / 2 - (height / 2);

    return [
        'resizable,scrollbars,status',
        'height=' + height,
        'width=' + width,
        'left=' + left,
        'top=' + top
    ].join();
}

function setupPopupShare() {
    $('a[data-popup]').on('click', function(event) {
        event.preventDefault();
        const win = window.open($(this).attr('href'), 'ShareAccommodation', shareWindowOptions());
        win.opener = null;
    });
}

$(document).ready(setupPopupShare);
