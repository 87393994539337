import React, { useState } from 'react';
import { OPEN_DOWN, OPEN_UP } from 'react-dates/constants';
import AllocationDateRangePicker from 'src/javascripts/components/allocations/AllocationDateRangePicker';
import AllocationSingleDatePicker from 'src/javascripts/components/allocations/AllocationSingleDatePicker';

export enum AllocationTypes {
    BLOCKER = 'Blocker',
    BOOKING = 'Booking'
}

export interface BaseDatePickerProps {
    accommodationId: number;
    allocationType: AllocationTypes;
    displayFormat: string;
    maxYears: number;
    openDirection?: OPEN_UP | OPEN_DOWN;
}

export interface AllocationDateRangePickerProps extends BaseDatePickerProps {
    startDatePlaceholderText: string;
    endDatePlaceholderText: string;
}

export interface AllocationSingleDatePickerProps extends BaseDatePickerProps {
    datePlaceholderText: string;
}

interface AllocationDatePickerProps extends AllocationDateRangePickerProps {
    indefiniteStayLabelText: string;
}

export default function AllocationDatePicker(props: AllocationDatePickerProps) {
    const [indefiniteStay, setIndefiniteStay] = useState(false);

    const datePicker = () => {
      if (indefiniteStay) {
          return <AllocationSingleDatePicker {...props} datePlaceholderText={props.startDatePlaceholderText} />;
      } else {
          return <AllocationDateRangePicker {...props} />;
      }
    };

    const handleCheckboxChange = () => setIndefiniteStay(!indefiniteStay);

    const indefiniteStayCheckbox = () => {
        if(props.indefiniteStayLabelText !== undefined) {
            return <div className="form-check mb-2">
                <input
                    className="form-check-input"
                    id="indefinite-stay"
                    name="booking[indefinite]"
                    type="checkbox"
                    value="true"
                    checked={indefiniteStay}
                    onChange={() => handleCheckboxChange()}/>
                <label className="form-check-label" htmlFor="indefinite-stay">{props.indefiniteStayLabelText}</label>
            </div>;
        }
    };

    return <div>
        { indefiniteStayCheckbox() }
        {datePicker()}
    </div>;
}
