import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import classNames from 'classnames';
import Village from '../../interfaces/village';
import AccommodationApi from '../../services/accommodationApi';

interface Props {
    placeholderText: string;
    initialSearchText?: string;
    inputFieldClassName?: string;
    suggestionsContainerClassName?: string;
    containerClassName?: string;
    onSelect(village: Village): void;
    onChange?(newValue: string): void;
    onSuggestionsChanged?(villages: Village[]): void;
}

export default function VillageSearchField({ placeholderText, onSelect, onChange, onSuggestionsChanged, initialSearchText = '',
                                             inputFieldClassName, suggestionsContainerClassName, containerClassName }: Props) {
    const maxPossibleVillages = 5;
    const fuseOptions: Fuse.IFuseOptions<Village> = {
        keys: ['name'],
        threshold: 0.2,
        shouldSort: true,
        minMatchCharLength: 1
    };
    const [searchText, setSearchText] = useState<string>(initialSearchText);
    const [{ villages, fuse }, setupSearch] = useState<{ villages: Village[], fuse: Fuse<Village> }>({
        villages: [],
        fuse: undefined
    });
    const [proposedVillages, setProposedVillages] = useState<Village[]>([]);

    useEffect(() => {
        AccommodationApi.villages().then(villages =>
            setupSearch({
                villages,
                fuse: new Fuse(villages, {
                    ...fuseOptions
                })
            }));
    }, []);

    useEffect(() => {
        if (onSuggestionsChanged) {
            onSuggestionsChanged(proposedVillages);
        }
    }, [proposedVillages]
    );

    const extractZipCode = (searchText: string): string => (
        '' + searchText.split(' ').map(v => parseInt(v, 10)).find(n => !isNaN(n))
    );

    const filter = (searchText: string) => {
        const searchedZipCode = extractZipCode(searchText);
        const searchedName = searchText.replace(searchedZipCode, '').trim();
        let possibleVillages = villages.filter(({ zipCode }) => zipCode.startsWith(searchedZipCode));
        if (possibleVillages.length) {
            if (searchedName) {
                possibleVillages = (new Fuse(possibleVillages, { keys: ['name'] })).search(searchedName)
                    .map(result => result.item);
            }
        } else if (searchedName) {
            possibleVillages = fuse.search<Village>(searchedName).map(result => result.item);
        } else {
            possibleVillages = [];
        }

        setProposedVillages(possibleVillages.slice(0, maxPossibleVillages));
    };

    const enterIsPressed = (event) => {
        return event.which === 13 || event.keyCode === 13;
    };

    const keyPress = (event) => {
        if (enterIsPressed(event) && proposedVillages.length > 0) {
            setSearchText(villageZipCity(proposedVillages[0]));
            onSelect(proposedVillages[0]);
        }
    };

    const onSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams) => filter(request.value);

    function villageZipCity(village: Village) {
        return [village.zipCode, village.name].join(' ');
    }

    return <Autosuggest
        suggestions={proposedVillages}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setProposedVillages([])}
        getSuggestionValue={(village: Village) => villageZipCity(village)}
        renderSuggestion={village => <div>{villageZipCity(village)}</div>}
        inputProps={{
            // @ts-ignore { ignore to use data-attribute instead of id }
            'data-village-search': '',
            className: inputFieldClassName || 'form-control',
            placeholder: placeholderText,
            value: searchText,
            onChange: (_, { newValue }) => {
                if (onChange) onChange(newValue);
                setSearchText(newValue);
            },
            onKeyPress: (e) => keyPress(e)
        }}
        onSuggestionSelected={(_, { suggestion }) => onSelect(suggestion)}
        theme={{
            container: classNames('accommodations-search-field h-100', containerClassName),
            suggestionsList: 'dropdown-menu d-block mt-1',
            suggestion: 'dropdown-item',
            suggestionHighlighted: 'active',
            containerOpen: 'open',
            suggestionsContainer: suggestionsContainerClassName || 'dropdown-menu-wrapper'
        }}
        focusInputOnSuggestionClick={false}
    />;
}
