import moment from 'moment';
import { Component } from 'react';
import { AllocationTypes, BaseDatePickerProps } from 'src/javascripts/components/allocations/AllocationDatePicker';

export abstract class AllocationBaseDatePicker<Props extends BaseDatePickerProps, State> extends Component<Props, State> {
    abstract isDayBlocked(day);

    isOutsideRange(day: moment.Moment) {
        return (day.isBefore(this.minDate()) || day.isAfter(this.maxDate()));
    }

    protected scopedFieldAttribute(attribute) {
        return `${this.props.allocationType.toLowerCase()}[${attribute}]`;
    }

    protected urlFor(params: { [param: string]: string | boolean; }) {
        const query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
        return `/accommodations/${this.props.accommodationId}/blocked_days.json?${query}`;
    }

    private minDate() {
        return moment().add(this.minimumDaysInAdvance(), 'day').startOf('day');
    }

    private minimumDaysInAdvance() {
        if (this.props.allocationType === AllocationTypes.BLOCKER) {
            return 0;
        }
        return moment().hours() < 12 ? 1 : 2;
    }

    private maxDate() {
        return moment().add(this.props.maxYears, 'years').endOf('day');
    }
}
