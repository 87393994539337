import { Bounds } from 'google-map-react';
import React, { useEffect, useState } from 'react';
import Filters, { FiltersTranslations } from 'src/javascripts/interfaces/filters';
import AccommodationApi from 'src/javascripts/services/accommodationApi';
import AccommodationsList from '../..//components/accommodations/AccommodationsList';
import AccommodationsMap from '../..//components/accommodations/AccommodationsMap';
import VillageSearchField from '../shared/VillageSearchField';
import AccommodationsSearchFilters from '../..//components/accommodations/AccommodationsSearchFilters';
import Accommodation, { AccommodationLabels } from '../..//interfaces/accommodation';
import Village from '../..//interfaces/village';

interface AccommodationsSearchProps {
    accommodationLabels: AccommodationLabels;
    filtersTranslations: FiltersTranslations;
    initialVillage?: Village;
    noResultText: string;
    placeholderText: string;
}

export default function({
                             accommodationLabels,
                             filtersTranslations,
                             initialVillage,
                             noResultText,
                             placeholderText
                         }: AccommodationsSearchProps) {
    const [village, setVillage] = useState<Village>(initialVillage);
    const [accommodations, setAccommodations] = useState<Accommodation[]>([]);
    const [selectedAccommodation, setSelectedAccommodation] = useState<Accommodation>();
    const [hoveredAccommodation, setHoveredAccommodation] = useState<Accommodation>();
    const [bounds, setBounds] = useState<Bounds>();
    const [filters, setFilters] = useState<Filters>({
            onlyBarrierFree: false,
            onlyFurnished: false,
            onlyWithAssistedLiving: false
        }
    );

    useEffect(() => {
            if (bounds) {
                performSearch();
            }
        }, [bounds, filters]
    );

    const performSearch = () => {
        AccommodationApi.search(bounds, filters).then(setAccommodations);
    };

    return (
        <div className="accommodations-search d-flex flex-column">
            <div className="accommodations-search-bar">
                <div className="search-field-container">
                    <VillageSearchField
                        initialSearchText={village && `${village.zipCode} ${village.name}`}
                        onSelect={setVillage}
                        placeholderText={placeholderText}
                    />
                </div>
                <div className="search-filters-container">
                    <AccommodationsSearchFilters
                        filtersTranslations={filtersTranslations}
                        filters={filters}
                        onChange={setFilters}
                    />
                </div>
            </div>
            <div className="search-results-container">
                <div className="map-size">
                    <AccommodationsMap
                        accommodations={accommodations}
                        clickAccommodation={setSelectedAccommodation}
                        hoveredAccommodation={hoveredAccommodation}
                        onChange={setBounds}
                        selectedAccommodation={selectedAccommodation}
                        village={village}
                    />
                </div>
                <div className="result-list-size">
                    <AccommodationsList
                        accommodationLabels={accommodationLabels}
                        accommodations={accommodations}
                        hoverAccommodation={setHoveredAccommodation}
                        noResultText={noResultText}
                        selectedAccommodation={selectedAccommodation}
                        village={village}
                    />
                </div>
            </div>
        </div>
    );
}
