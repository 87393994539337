export default class MarketplaceCategories {
  constructor() {
    if ($('.droppable').length > 0) {
      $('.droppable').sortable({
        connectWith: 'ul',
        placeholder: 'placeholder',
        delay: 150,
        start: () => $('.marketplace-categories-container').addClass('is-dragging'),
        stop: () => $('.marketplace-categories-container').removeClass('is-dragging'),
        update: (_event, ui) => this.postMove(this.postMoveParams(ui))
      }).disableSelection();
      $('.loading-overlay').hide();
    }
  }

  private postMove (args) {
    const [id, params] = args;

    $('.loading-overlay').show();
    $.post(`${One11.backendUrl}admin/marketplace/categories/${id}/move`, params, () => {
      $('.loading-overlay').hide();
    });
  }

  private postMoveParams (ui) {
    const activeNodeId = ui.item.data('id');
    const previousNodeId = ui.item.prev().data('id');
    const parentNodeId = ui.item.parent().parent().data('id');

    if (previousNodeId) {
      return [activeNodeId, { previous_id: previousNodeId }];
    } else if(parentNodeId) {
      return [activeNodeId, { parent_id: parentNodeId }];
    } else {
      return [activeNodeId, { }];
    }
  }
}
