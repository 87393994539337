import React, { useState } from 'react';
import { PaymentMethod } from 'src/javascripts/interfaces/payment';

interface PaymentMethodRadioGroupProps {
    paymentMethods: PaymentMethod[];
    selectedPaymentMethod: PaymentMethod;
    onChange: (PaymentMethod) => void;
}

export default function PaymentMethodRadioGroup({paymentMethods, selectedPaymentMethod, onChange}: PaymentMethodRadioGroupProps) {
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(selectedPaymentMethod);

    const handleOnChange = ({target: {value}}) => {
        onChange(value);
        setPaymentMethod(value);
    };

    const iconClass = (paymentMethod: PaymentMethod) => {
        return {
            cash: 'fa-money-bill-wave',
            credit_card: 'fa-credit-card',
            invoice: 'fa-file-invoice'
        }[paymentMethod];
    };
    const paymentMethodRadio = (paymentMethod: PaymentMethod) => {
        const isPaymentMethodAvailable =  paymentMethods.includes(paymentMethod);
        return isPaymentMethodAvailable && <label className="btn paymentMethod">
            <input type="radio"
                   checked={isChecked(paymentMethod)}
                   name="booking[payment_method]"
                   value={paymentMethod}
                   onChange={handleOnChange}
            />
            <div>
                <i className={'far fa-2x ' + iconClass(paymentMethod)}/>
            </div>
            {One11.i18n.payment.payment_methods[paymentMethod]}
        </label>;
    };

    const isChecked = (value: PaymentMethod) => paymentMethod === value;

    return <div className="paymentWrap">
        <div className="btn-group paymentBtnGroup btn-group-justified">
            {paymentMethodRadio('cash')}
            {paymentMethodRadio('credit_card')}
            {paymentMethodRadio('invoice')}
        </div>
    </div>;

}
