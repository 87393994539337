import React, { useState } from 'react';
import Accommodation, { AccommodationLabels } from 'src/javascripts/interfaces/accommodation';

interface AccommodatiosListEntryProps {
    accommodation: Accommodation;
    accommodationLabels: AccommodationLabels;
    hoverAccommodation: (accommodation: Accommodation) => void;
}

export default function AccommodationsListEntry({
                                                    accommodation,
                                                    accommodationLabels,
                                                    hoverAccommodation
                                                }: AccommodatiosListEntryProps) {
    const [height, setHeight] = useState<number>(0);

    const falseIcon = <i className="fa-fw far fa-times"/>;
    const trueIcon = <i className="fa-fw far fa-check"/>;
    const barrierFreeIcon = (accommodation.barrierFree) ? trueIcon : falseIcon;
    const furnishedIcon = (accommodation.furnished) ? trueIcon : falseIcon;

    function setHeightForPicture(ref) {
        if(ref && !height) {
            setHeight(ref.clientHeight);
        }
    }

    return <a href={accommodation.urlPath}
        className="list-entry hyphened d-block"
        onMouseOver={() => hoverAccommodation(accommodation)}
        onMouseLeave={() => hoverAccommodation(null)}
        ref={ref  => setHeightForPicture(ref)}
        target="_blank"
    >
        <div className="row h-100 no-gutters">
            <div className="col-md-5">
                {accommodation.pictures[0] &&
                <img className="img-fluid" style={{height}} src={accommodation.pictures[0].url} alt={accommodation.name}/>}
            </div>
            <div className="col-md-7">
                <div className="list-entry-body">
                    <div className="list-entry-header d-flex mb-3">
                        <p className="mb-0 mr-auto text-muted">{accommodation.translatedCategory}</p>
                        <p className="mb-0 text-muted">{`${accommodation.zip} ${accommodation.city}`}</p>
                    </div>
                    <div className="list-entry-content flex-grow-1">
                        <h2 className="d-inline-block mb-3">{accommodation.qualitySealActive &&
                        <i className="dark-green far fa-shield-check mr-2"/>}{accommodation.name}</h2>
                    </div>
                    <div className="list-entry-footer container-fluid px-0">
                        <p className="mb-0">
                            <span className="text-muted">
                                {`${accommodationLabels.shortStay}: `}
                            </span>
                            {accommodation.formattedShortStayPrice.toLocaleString(...One11.numberFormatOptions)}
                        </p>
                        <p className="mb-0">
                            <span className="text-muted">
                                {`${accommodationLabels.longStay}: `}
                            </span>
                            {accommodation.formattedLongStayPrice.toLocaleString(...One11.numberFormatOptions)}
                        </p>
                        <p className="mb-0">
                            <span className="text-muted">
                                {`${accommodationLabels.numberOfRooms}: `}
                            </span>
                            {accommodation.numberOfRooms}
                        </p>
                        <p className="mb-0">
                            <span className="text-muted">
                                {`${accommodationLabels.furnished}? `}
                            </span>
                            {furnishedIcon}
                        </p>
                        <p className="mb-0">
                            <span className="text-muted">
                                {`${accommodationLabels.barrierFree}? `}
                            </span>
                            {barrierFreeIcon}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>;
}
