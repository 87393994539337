import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'first', 'second' ]

  switchStyle() {
    const hasFirstClass = this.element.classList.contains(this.firstClass);
    this.element.classList.toggle(this.firstClass, !hasFirstClass)
    this.element.classList.toggle(this.secondClass, hasFirstClass)
  }
}

