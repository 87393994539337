import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="walkthrough"
export default class extends Controller {
  static targets = ['step'];

  toggleVisibility(target, visible) {
    if (visible === undefined) {
      target.classList.toggle('d-none');
    } else {
      target.classList.toggle('d-none', !visible);
    }
  }

  to_step({ params: { step } }) {
    const previousStep = step === 0 ? this.stepTargets.length - 1 : step - 1;
    this.toggleVisibility(this.stepTargets[previousStep], false);
    this.toggleVisibility(this.stepTargets[step], true);
  }
}
