import React, { useState } from 'react';
import VillageSearchField from '../shared/VillageSearchField';
import Village from '../..//interfaces/village';
import ListingsSearchApi from '../..//services/listingsSearchApi';

interface ListingsSearchProps {
    noResultText: string;
    wherePlaceholderText: string;
    whatPlaceholderText: string;
    categoryLabelText: string;
    whereLabelText: string;
    whatLabelText: string;
    categoryPlaceholderText: string;
    categoryCollection: Array<string>;
    apiLocation: string;
    initialWhatSearchTerm: string;
    initialVillage: string;
    initialCategory: string;
    buttonText: string;
    listingTypeCollection: Array<string>;
    listingTypePlaceholderText: string;
    initialListingType: string;
    listingTypeLabelText: string;
}

export default function ({
    wherePlaceholderText,
    whatPlaceholderText,
    initialWhatSearchTerm,
    categoryPlaceholderText,
    categoryCollection,
    whatLabelText,
    categoryLabelText,
    whereLabelText,
    initialCategory,
    apiLocation,
    buttonText,
    initialVillage,
    listingTypeCollection,
    listingTypePlaceholderText,
    initialListingType,
    listingTypeLabelText
}: ListingsSearchProps) {
    const [village, setVillage] = useState<Village>();
    const [whatSearchTerm, setWhatSearchTerm] = useState<string>(initialWhatSearchTerm || '');
    const [category, setCategory] = useState<string>(initialCategory || '');
    const [listingType, setListingType] = useState<string>(initialListingType || '');

    const updateSearchResultContainer = (apiResponse) => {
        document.querySelector('[data-search-result-container]').innerHTML = apiResponse.resultContent;
    };

    const pushToHistroy = () => {
        const url = new URL(apiLocation);
        if (village) {
            url.searchParams.set('where', `${village.zipCode} ${village.name}`);
        }
        if (whatSearchTerm) {
            url.searchParams.set('what', whatSearchTerm);
        }
        if (category) {
            url.searchParams.set('category', category);
        }
        if (listingType) {
            url.searchParams.set('listing_type', listingType);
        }
        window.location.replace(url.toString());
    };

    const submitSearchRequest = (e) => {
        e.preventDefault();
        pushToHistroy();
        ListingsSearchApi.search(
            whatSearchTerm, [village?.zipCode, village?.name].join(' '), category, listingType
        ).then(res => {
            res.json().then(content => updateSearchResultContainer(content));
        });
    };

    const checkEmptySearch = value => {
        if (value === '') setVillage(null);
    };

    return (
        <form className="listings-search">
            <div className="d-md-flex d-block form-inline listing-form mb-n5 mb-md-0">
                <div className="row no-gutters mb-3 mb-md-0 mr-md-3">
                    <div className="d-md-none col-4 my-auto">{whatLabelText}</div>
                    <input id="what"
                        type="text"
                        className="form-control search-field col px-2"
                        onChange={(e) => setWhatSearchTerm(e.target.value)}
                        placeholder={whatPlaceholderText}
                        value={whatSearchTerm} />
                </div>
                <div className="row no-gutters mb-3 mb-md-0 mr-md-3">
                    <div className="d-md-none col-4 my-auto">{whereLabelText}</div>
                    <VillageSearchField
                        initialSearchText={initialVillage || ''}
                        onSelect={setVillage}
                        onChange={checkEmptySearch}
                        placeholderText={wherePlaceholderText}
                        containerClassName="col"
                    />
                </div>
                <div className="row no-gutters mb-3 mb-md-0 mr-md-3">
                    <div className="d-md-none col-4 my-auto">{categoryLabelText}</div>
                    <select
                        className="category-selection form-control search-field col px-2"
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <option key="empty-option" value="">{categoryPlaceholderText}</option>
                        {(categoryCollection || []).map(categoryName => (
                            <option key={categoryName} value={categoryName}>{categoryName}</option>
                        ))}
                    </select>
                </div>
                <div className="row no-gutters mb-3 mb-md-0 mr-md-3">
                    <div className="d-md-none col-4 my-auto">{listingTypeLabelText}</div>
                    <select
                        className="listing-type-selection form-control search-field col px-2"
                        id="listing_type"
                        value={listingType}
                        onChange={(e) => setListingType(e.target.value)}
                    >
                        <option key="empty-option" value="">{listingTypePlaceholderText}</option>
                        {(Object.keys(listingTypeCollection || {})).map(listingTypeName => (
                            <option key={listingTypeName} value={listingTypeName}>{listingTypeCollection[listingTypeName]}</option>
                        ))}
                    </select>
                </div>
                <button data-search-submit-button=""
                    className="btn btn-primary mt-3 mt-md-0 ml-md-3 align-items-center search-button"
                    onClick={submitSearchRequest}>{buttonText}</button>
            </div>
        </form>
    );
}
