import React, { useState } from 'react';
import VillageSearchField from '../shared/VillageSearchField';
import Village from '../..//interfaces/village';

interface ListingsSearchProps {
    redirectPath: string;
    placeholder: string;
    buttonText: string;
}

export default function ({
    placeholder,
    redirectPath,
    buttonText
}: ListingsSearchProps) {
    const [village, setVillage] = useState<Village>();

    const redirectToSearch = () => {
        const url = new URL(redirectPath);
        url.searchParams.set('where', village ? `${village.zipCode} ${village.name}` : '');
        window.location.href = url.toString();
    };

    return (
        <div className="listings-search">
            <div className="d-flex">
                <div className="input-extension border-right-0 bg-white d-flex rounded-left pl-2 mr-n2">
                    <i className="far fa-search my-auto text-primary" />
                </div>
                <div className="w-100">
                    <VillageSearchField
                        initialSearchText={''}
                        onSelect={setVillage}
                        placeholderText={placeholder}
                        inputFieldClassName={'form-control py-4'}
                    />
                </div>
                <div className="input-extension border-left-0 bg-white d-flex rounded-right pr-1 ml-n2">
                    <button data-search-submit-button=""
                        className="btn btn-primary my-auto"
                        onClick={redirectToSearch}>
                        {buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
}
