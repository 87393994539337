import React, { useState } from 'react';
import VillageSearchField from '../shared/VillageSearchField';
import Village from '../../interfaces/village';

interface LivingSearchProps {
    placeholderText: string;
    searchButtonText: string;
    redirectUrl: string;
    defaultVillage: Village;
}

export default function LivingSearch({
                                           searchButtonText, placeholderText,
                                           redirectUrl, defaultVillage
                                       }: LivingSearchProps) {
    const [proposedVillages, setProposedVillages] = useState<Village[]>([]);

    const onSelect = (village: Village) => {
        window.location.assign(`${redirectUrl}&${$.param({village})}`);
    };

    const onSearchButtonClick = (e) => {
        onSelect(proposedVillages.length > 0 ? proposedVillages[0] : defaultVillage);
    };

    return (
        <div className="d-flex">
            <div className="w-100">
                <div className="accommodations-search">
                    <VillageSearchField
                        onSuggestionsChanged={setProposedVillages}
                        placeholderText={placeholderText}
                        onSelect={onSelect}
                    />
                </div>
            </div>
            <button name="submit-search" className="btn btn-search d-flex align-items-center ml-3"
                    onMouseDown={onSearchButtonClick}>{searchButtonText}</button>
        </div>
    );
}
