export default class LoaderIndicator {
    constructor() {
        this.attachIndicator();
    }

    attachIndicator() {
        $("[data-indicate-loading='true']").off('click');
        $("[data-indicate-loading='true']").on('click', function(event) {
            $.busyLoadFull(
                    "show",
                    {
                        fontawesome: "fa fa-cog fa-spin fa-3x fa-fw"
                    }
            );
        });
    }
}
