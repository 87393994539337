import Sortable from 'sortablejs';

$(document).on('turbolinks:load', () => {
    if ($('[data-toggle-filter]').length > 0) {
        $('[data-toggle-submit]').on('click', () => {
            $('[data-toggle-filter]').trigger('submit');
        });
    }

    if ($('[data-image-upload]').length > 0) {
        $('.custom-file input').on('change', (event) => {
            const field = event.target as HTMLInputElement;
            const isMultipleLines = !!$(field).parent('.multiple-input-file').length;
            const files = Array.from(field.files);
            const fileNames = isMultipleLines &&
                files.map(file => `<div>${file.name}</div>`).join('') ||
                files.map(file => file.name).join(', ');
            const label = $(field).next('.custom-file-label');
            label.html(files.length > 0 ? fileNames : label.attr('data-label-placeholder'));
        });

        $('[data-sortable]').each((_, placeholder) => {
            new Sortable(placeholder, {
                onSort: (event) => {
                    $(event.item).find('[data-position]').val(event.newIndex.toString());
                }
            });
        });
    }
});
