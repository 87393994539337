import classNames from 'classnames';
import GoogleMapReact, { Bounds } from 'google-map-react';
import React from 'react';
import Accommodation from '../../interfaces/accommodation';
import Village from '../../interfaces/village';
import { MapStyleOptions } from './map_style_options';

interface MarkerProps {
    lat: number;
    lng: number;
    key: number;
    selected: boolean;
    hovered: boolean;
    markerIcon: string;
    onClick(): void;
}

function Marker({markerIcon, onClick, selected, hovered}: MarkerProps) {
    return <div className={classNames('marker', {selected, hovered})} onClick={onClick}>
        <i className={markerIcon} />
    </div>;
}

export interface AccommodationsMapProps {
    village: Village;
    accommodations: Accommodation[];
    selectedAccommodation: Accommodation;
    hoveredAccommodation: Accommodation;
    clickAccommodation(accommodation: Accommodation): void;
    onChange(Bounds): void;
}

export default function AccommodationsMap({
                                                    village,
                                                    accommodations,
                                                    onChange,
                                                    clickAccommodation,
                                                    selectedAccommodation,
                                                    hoveredAccommodation
                                                }: AccommodationsMapProps) {

    if (!village) {
        return null;
    }

    const MAX_MOBILE_DEVICE_WIDTH = 576;
    const isSmallScreen = window.innerWidth < MAX_MOBILE_DEVICE_WIDTH;

    return <div className="accommodations-search-map">
        <GoogleMapReact
            bootstrapURLKeys={{key: One11.googleMapsKey}}
            center={{lat: village.latitude, lng: village.longitude}}
            defaultCenter={{lat: 46.8077173, lng: 7.1034692}}
            defaultZoom={isSmallScreen ? 12 : 14}
            options={ {styles: MapStyleOptions }}
            onChange={({bounds}) => onChange(bounds)}
        >
            {accommodations.map(accommodation =>
                <Marker
                    key={accommodation.id}
                    selected={selectedAccommodation && accommodation.id === selectedAccommodation.id}
                    hovered={hoveredAccommodation && accommodation.id === hoveredAccommodation.id}
                    onClick={() => clickAccommodation(accommodation)}
                    markerIcon={'far fa-bed fa-fw'}
                    lat={accommodation.coordinates.latitude}
                    lng={accommodation.coordinates.longitude}
                />
            )}
        </GoogleMapReact>
    </div>;
}
