import React from 'react';
import ReactList from 'react-list';
import Village from 'src/javascripts/interfaces/village';
import AccommodationsListEntry from '../../components/accommodations/AccommodationsListEntry';
import Accommodation, { AccommodationLabels } from '../../interfaces/accommodation';

interface AccommodationsListProps {
    accommodationLabels: AccommodationLabels;
    accommodations: Accommodation[];
    hoverAccommodation: (accommodation: Accommodation) => void;
    noResultText: string;
    selectedAccommodation?: Accommodation;
    village: Village;
}

export default function AccommodationsList({
                                               accommodationLabels,
                                               accommodations,
                                               hoverAccommodation,
                                               noResultText,
                                               selectedAccommodation,
                                               village
                                           }: AccommodationsListProps) {
    if (!village) {
        return null;
    }

    if (accommodations.length === 0) {
        return <div className="container px-3 pt-3">
            <div className="alert alert-info">
                {noResultText}
            </div>
        </div>;
    }

    let accommodationsToDisplay = accommodations;
    if (selectedAccommodation) {
        accommodationsToDisplay = accommodationsToDisplay.filter(accommodation => accommodation.id !== selectedAccommodation.id);
    }

    const renderItem = (index, key) => {
        const accommodation = accommodationsToDisplay[index];
        return <AccommodationsListEntry
            key={key}
            accommodation={accommodation}
            accommodationLabels={accommodationLabels}
            hoverAccommodation={hoverAccommodation}
        />;
    };

    return <div className="accommodations-list p-md-3 px-3">
        {
            selectedAccommodation &&
            <>
                <AccommodationsListEntry
                    key={selectedAccommodation.id}
                    accommodation={selectedAccommodation}
                    accommodationLabels={accommodationLabels}
                    hoverAccommodation={hoverAccommodation}
                />
                <hr />
            </>
        }
        <ReactList
            itemRenderer={renderItem}
            length={accommodationsToDisplay.length}
            type="simple" // Uniform would have better performance but doesn't work properly with margins.
        />
    </div>;
}
