class InputLengthValidator {
  inputEl: JQuery<HTMLElement>;
  progressEl: JQuery<HTMLElement>;
  config: { maxLength: number };

  constructor (valiatableRoot: string) {
    this.inputEl = $(valiatableRoot).find('.inline-validatable-input');
    this.progressEl = $(valiatableRoot).find('.inline-validatable-progress');
    this.config = { maxLength: parseInt($(this.inputEl).attr('maxlength'), 10) };

    $(this.inputEl).on('keyup change paste', this.triggerValidations);
    $(this.inputEl).on('keydown', this.indicateLengthOverreach);
    this.triggerValidations();
  }

  private triggerValidations = () => {
    this.validateLength((this.inputEl.val() as string));
  }

  private validateLength = (value: string) => {
    if (!this.config.maxLength) return;

    this.updateValidationProgress({
      message: `${value.length} / ${this.config.maxLength}`,
      limitReached: value.length >= this.config.maxLength
    });
  }

  private updateValidationProgress = ({message, limitReached}) => {
    this.progressEl.text(message);
    this.progressEl.toggleClass('limit-reached', limitReached);
    this.inputEl.toggleClass('limit-reached', limitReached);
  }

  private indicateLengthOverreach = () => {
    this.inputEl.removeClass('limit-length-overreached');
    setTimeout(() =>
      this.inputEl.toggleClass('limit-length-overreached', ((this.inputEl.val() as string).length >= this.config.maxLength))
    , 1);
  }
}

export default InputLengthValidator;
