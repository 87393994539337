import Rails from '@rails/ujs';

$(document).on('turbolinks:load', () => {
    if (One11.googleRecaptchaEnabled) {
        $("[data-recaptcha='true']").on('submit', (event: JQuery.SubmitEvent) => {
            if ($('input[name="recaptcha"]').length === 0) {
                // TODO: wait for https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33540
                (grecaptcha as unknown as any).ready(() => {
                    (grecaptcha as unknown as any).execute(One11.googleRecaptchaKey, {action: 'new_contact'})
                        .then((token) => {
                            const hiddenTag = $('<input type="hidden" name="recaptcha">');
                            hiddenTag.val(token);
                            $(event.target).append(hiddenTag);

                            // Rails.fire doesn't support non-remote forms, so check if the form is remote
                            if (event.target.dataset.remote) {
                                Rails.fire(event.target, 'submit');
                            } else {
                                event.target.submit();
                            }
                        });
                });
                return false;
            }
        });
    }
});
