import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm, { CheckoutFormProps } from './CheckoutForm';

interface CheckoutProps extends CheckoutFormProps {
    publicApiKey: string;
}

export default function Checkout({publicApiKey, paymentMethods, selectedPaymentMethod, cashInformationLabel, email}: CheckoutProps) {
    let stripePromise = null;

    if(publicApiKey) {
        stripePromise = loadStripe(publicApiKey);
    }
    return <div className="checkout">
        <div className="checkout-form">
            <Elements stripe={stripePromise}>
                <CheckoutForm
                    paymentMethods={paymentMethods}
                    selectedPaymentMethod={selectedPaymentMethod}
                    cashInformationLabel={cashInformationLabel}
                    email={email}
                />
            </Elements>
        </div>
    </div>;
}
