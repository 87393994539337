import Rails from '@rails/ujs';

const removeNullKeys = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop]) { newObj[prop] = obj[prop]; }
    });
    return newObj;
};

export default class ListingsSearchApi {
    static search(whatSearchTerm: string, whereSearchTerm: string, categoryName: string, listingTypeName: string): Promise<any> {

        const bodyContent = JSON.stringify({
            search: removeNullKeys({
                locale: One11.locale,
                where: whereSearchTerm,
                what: whatSearchTerm,
                category: categoryName,
                listing_type: listingTypeName
            })

        });

        return fetch('/marketplace/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': Rails.csrfToken()
            },
            body: bodyContent
        }).then(res => res);
    }
}
