export default class FormChangeListener {
    private changed = false;

    constructor(private form: HTMLFormElement, private confirmQuestion: string) {
        if (form.length > 0) {
            this.initListener();
        }
    }

    private initListener() {
        // EventListeners use document as `this`, and therefore would fail.
        // `.bind(this)` is neither liked by the linter
        // tslint:disable-next-line:no-this-assignment
        const self = this;
        $(this.form).on('change', () => self.setChanged(true));
        document.addEventListener('turbolinks:load', () => self.setChanged(false));
        document.addEventListener('turbolinks:before-visit', (event) => self.handlePageChange(event));
        $(window).on('beforeunload', (event) => self.handleUnload(event as unknown as BeforeUnloadEvent));
        $(this.form).on('submit', () => self.handleSubmit());
    }

    private handlePageChange(event: Event) {
        if (this.changed && !confirm(this.confirmQuestion)) {
            event.preventDefault();
        }
    }

    private handleUnload(event: BeforeUnloadEvent) {
        if (this.changed && !confirm(this.confirmQuestion)) {
            event.returnValue = this.confirmQuestion;
        }
    }

    private setChanged(value: boolean) {
        this.changed = value;
    }

    private handleSubmit() {
        $(window).off('beforeunload');
        this.setChanged(false);
        return true;
    }
}
