export default class TimeShareTransactionHandler {
    private readonly ENTER_KEY_CODE = 13;
    private readonly SUBMISSION_BUTTON_SELECTOR = 'data-transaction-submit-button';
    private readonly RECIPIENT_ID_FIELD_SELECTOR = 'data-transaction-opposite-actor';
    private readonly OPERATION_FIELD_SELECTOR = 'data-transaction-operation';
    private readonly AMOUNT_FIELD_SELECTOR = 'data-transaction-amount';
    private readonly AMOUNT_TITLE_SELECTOR = 'data-transaction-amount-title';
    private readonly SEARCH_TITLE_SELECTOR = 'data-transaction-search-title';
    private readonly SELECTED_OPERATION_SELECTOR = 'data-transaction-selected-operation';
    private readonly SELECTED_AMOUNT_SELECTOR = 'data-transaction-selected-amount';
    private readonly SEARCH_BUTTON_SELECTOR = 'data-user-search-submit';
    private readonly SEARCH_INPUT_SELECTOR = 'data-user-search-input';
    private readonly SEARCH_CARD_SELECTOR = 'data-user-search-card';
    private readonly MESSAGE_FIELD_SELECTOR = 'data-transaction-message';

    private readonly TRANSACTION_INPUT_FIELDS = [
        this.RECIPIENT_ID_FIELD_SELECTOR,
        this.AMOUNT_FIELD_SELECTOR,
        this.OPERATION_FIELD_SELECTOR,
        this.MESSAGE_FIELD_SELECTOR
    ];

    register() {
        const transactionForm = $(this.selectorFromDataAttribute(this.SUBMISSION_BUTTON_SELECTOR));
        if (!transactionForm.length) {
            return;
        }
        this.registerFields();
    }

    setSelectedUser(id: number): void {
        $(this.selectorFromDataAttribute(this.RECIPIENT_ID_FIELD_SELECTOR)).val(id);
        this.fieldChange(null);
    }

    operationSelection = (event): void => {
        const target = event.target;
        const operationSelection: string = target.value;
        this.setFormTexts(operationSelection);
    }

    onSearchUserButtonClick = (event): void => {
        event.preventDefault();
        const url = $(this.selectorFromDataAttribute(this.SEARCH_BUTTON_SELECTOR)).attr('href');
        const transactionIdentifier = $(this.selectorFromDataAttribute(this.SEARCH_INPUT_SELECTOR)).val();
        this.submitSelectedUser({ url, transaction_identifier: transactionIdentifier });
    }

    onSearchUserCardClick = (event): void => {
        event.preventDefault();
        const userCard = $(event.target).closest(this.selectorFromDataAttribute(this.SEARCH_CARD_SELECTOR));
        const url = userCard.attr('href');
        const userId = userCard.attr('data-user-search-card-id');
        this.submitSelectedUser({ url, id: userId });
    }

    submitSelectedUser = ({ url, transaction_identifier = null, id = null }): void => {
        $.ajax(url, {
            method: 'POST',
            data: {
                transaction_user_search: {
                    amount: $(this.selectorFromDataAttribute(this.AMOUNT_FIELD_SELECTOR)).val(),
                    operation: $(`${this.selectorFromDataAttribute(this.OPERATION_FIELD_SELECTOR)}:input:checked`).val(),
                    transaction_identifier,
                    id
                }
            }
        });
    }

    onSearchUserEnterKey = (event): void => {
        if (event.keyCode === this.ENTER_KEY_CODE) $(this.selectorFromDataAttribute(this.SEARCH_BUTTON_SELECTOR)).trigger('click');
    }

    setAmountSelection = (event): void => {
        const target = event.target;
        const amount: string = target.value;
        $(this.selectorFromDataAttribute(this.SELECTED_AMOUNT_SELECTOR)).text(amount);
    }

    onAmountMinusButtonClick = (event): void => {
        const amountInput = $(this.selectorFromDataAttribute(this.AMOUNT_FIELD_SELECTOR));
        amountInput.val((_i, val) => {
            if (val === '') return amountInput.attr('min');

            const result = (parseFloat(val) - parseFloat(amountInput.attr('step')));
            return result >= parseFloat(amountInput.attr('min')) ? result.toString() : val;
        });
        $(this.selectorFromDataAttribute(this.SELECTED_AMOUNT_SELECTOR)).text(amountInput.val().toString());
        this.fieldChange(event);
    }

    onAmountPlusButtonClick = (event): void => {
        const amountInput = $(this.selectorFromDataAttribute(this.AMOUNT_FIELD_SELECTOR));
        amountInput.val((_i, val) => {
            if (val === '') return amountInput.attr('min');

            return (parseFloat(val) + parseFloat(amountInput.attr('step'))).toString();
        });
        $(this.selectorFromDataAttribute(this.SELECTED_AMOUNT_SELECTOR)).text(amountInput.val().toString());
        this.fieldChange(event);
    }

    private registerFields(): void {
        this.registerField(this.MESSAGE_FIELD_SELECTOR, 'keydown', this.fieldChange);
        this.registerField(this.AMOUNT_FIELD_SELECTOR, 'change', this.fieldChange);
        this.registerField(this.OPERATION_FIELD_SELECTOR, 'change', this.fieldChange);

        this.registerField(this.OPERATION_FIELD_SELECTOR, 'change', this.operationSelection);
        this.registerField(this.AMOUNT_FIELD_SELECTOR, 'change', this.setAmountSelection);
        this.registerField(this.SEARCH_BUTTON_SELECTOR, 'click', this.onSearchUserButtonClick);
        this.registerField(this.SEARCH_INPUT_SELECTOR, 'keyup', this.onSearchUserEnterKey);
        this.registerField(this.SEARCH_CARD_SELECTOR, 'click', this.onSearchUserCardClick);
        this.registerField('data-amount-minus-button', 'click', this.onAmountMinusButtonClick);
        this.registerField('data-amount-plus-button', 'click', this.onAmountPlusButtonClick);
    }

    private registerField(attribute: string, event: string, handler): void {
        $(document).on(event, this.selectorFromDataAttribute(attribute), handler);
    }

    private fieldChange = (event: Event): void => {
        if (this.validateForm()) {
            this.enableSubmissionButton();
        } else {
            this.disableSubmissionButton();
        }
    }

    private setFormTexts(operationSelection: string): void {
        const transactionTranslations = One11.i18n.time_share;

        const submitButtonElement = $(this.selectorFromDataAttribute(this.SUBMISSION_BUTTON_SELECTOR));
        submitButtonElement.val(transactionTranslations.submit_button[operationSelection].toString());

        const amountTitleElement = $(this.selectorFromDataAttribute(this.AMOUNT_TITLE_SELECTOR));
        amountTitleElement.text(transactionTranslations.amount_title[operationSelection].toString());

        const searchTitleElement = $(this.selectorFromDataAttribute(this.SEARCH_TITLE_SELECTOR));
        searchTitleElement.text(transactionTranslations.search_title[operationSelection].toString());

        const transactionOperationElement = $(this.selectorFromDataAttribute(this.SELECTED_OPERATION_SELECTOR));
        transactionOperationElement.text(transactionTranslations.search_operation[operationSelection].toString());
    }

    private validateForm(): boolean {
        return this.TRANSACTION_INPUT_FIELDS.map(field => {
            const domElement = (document.querySelector(this.selectorFromDataAttribute(field)) as HTMLInputElement);
            return domElement.value !== '' && domElement.value !== null && domElement.checkValidity();
        }).every(validation => validation === true);
    }

    private enableSubmissionButton(): void {
        $(this.selectorFromDataAttribute(this.SUBMISSION_BUTTON_SELECTOR)).removeAttr('disabled');
    }

    private disableSubmissionButton(): void {
        $(this.selectorFromDataAttribute(this.SUBMISSION_BUTTON_SELECTOR)).attr('disabled', 'true');
    }

    private selectorFromDataAttribute(attribute: string): string {
        return `[${attribute}]`;
    }
}
