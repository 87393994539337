import moment from 'moment';
import React from 'react';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';
import { OPEN_UP } from 'react-dates/constants';
import 'react-dates/initialize';
import { AllocationBaseDatePicker } from 'src/javascripts/components/allocations/AllocationBaseDatePicker';
import { AllocationDateRangePickerProps } from 'src/javascripts/components/allocations/AllocationDatePicker';
import { DateString, toDateString } from 'src/javascripts/components/allocations/DateString';

interface DateRangePickerState {
    focusedInput: DateRangePickerShape['focusedInput'];
    startDate: DateRangePickerShape['startDate'];
    endDate: DateRangePickerShape['endDate'];
    blockedDays: {
        startDate: DateString[],
        endDate: DateString[]
    };
}

export default class AllocationDateRangePicker extends AllocationBaseDatePicker<AllocationDateRangePickerProps, DateRangePickerState> {
    /* istanbul ignore next */

    // https://github.com/gotwarlost/istanbul/issues/690
    constructor(props) {
        super(props);

        this.state = {
            focusedInput: null,
            startDate: null,
            endDate: null,
            blockedDays: {
                startDate: [],
                endDate: []
            }
        };
        this.isDayBlocked = this.isDayBlocked.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
    }

    isDayBlocked(day: moment.Moment): boolean {
        const formattedDay = toDateString(day);
        if (this.startDateSelected() && this.endDateFocused()) {
            return (formattedDay >= this.firstBlockedAfterStartDate() || formattedDay < toDateString(this.state.startDate));
        } else if (this.endDateSelected() && !this.startDateSelected() && this.startDateFocused()) {
            return (formattedDay <= this.lastBlockedBeforeEndDate()) || (formattedDay > toDateString(this.state.endDate));
        } else {
            // this can be optimized with binary search since the array is sorted.
            return this.state.blockedDays[this.state.focusedInput].includes(formattedDay);
        }
    }

    componentDidMount() {
        const startsOnParams = {'filter[type]': this.props.allocationType, 'filter[attribute]': 'starts_on'};
        const endsOnParams = {'filter[type]': this.props.allocationType, 'filter[attribute]': 'ends_on'};

        fetch(this.urlFor(startsOnParams))
            .then(response => response.json())
            .then(data => {
                this.setState(prevState => ({
                    ...prevState,
                    blockedDays: {
                        startDate: data,
                        endDate: prevState.blockedDays.endDate
                    }
                }));
            });
        fetch(this.urlFor(endsOnParams))
            .then(response => response.json())
            .then(data => {
                this.setState(prevState => ({
                    ...prevState,
                    blockedDays: {
                        startDate: prevState.blockedDays.startDate,
                        endDate: data
                    }
                }));
            });
    }

    render() {
        return (
            <DateRangePicker
                startDateId={this.scopedFieldAttribute('starts_on')}
                endDateId={this.scopedFieldAttribute('ends_on')}
                displayFormat={this.props.displayFormat}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                startDatePlaceholderText={this.props.startDatePlaceholderText}
                endDatePlaceholderText={this.props.endDatePlaceholderText}
                showDefaultInputIcon={true}
                focusedInput={this.state.focusedInput}
                isDayBlocked={this.isDayBlocked}
                isOutsideRange={this.isOutsideRange}
                openDirection={this.props.openDirection || OPEN_UP}
                numberOfMonths={1}
                onDatesChange={({startDate, endDate}) => this.onDatesChange(startDate, endDate)}
                onFocusChange={(focusedInput) => {
                    this.setState({focusedInput});
                }}
            />
        );
    }

    private startDateFocused(): boolean {
        return this.state.focusedInput === 'startDate';
    }

    private endDateFocused(): boolean {
        return this.state.focusedInput === 'endDate';
    }

    private startDateSelected(): boolean {
        return this.state.startDate != null;
    }

    private endDateSelected(): boolean {
        return this.state.endDate != null;
    }

    private onDatesChange(startDate, endDate) {
        if (startDate !== this.state.startDate && this.endDateSelected()) {
            const m = moment(startDate).add(1, 'days');
            for (m; m.isBefore(endDate); m.add(1, 'days')) {
                if (this.state.blockedDays[this.state.focusedInput].includes(toDateString(m))) {
                    endDate = null;
                    break;
                }
            }
        }
        this.setState({startDate, endDate});
    }

    private firstBlockedAfterStartDate(): string {
        return this.state.blockedDays[this.state.focusedInput].find((el) => {
            return el > toDateString(this.state.startDate);
        });
    }

    private lastBlockedBeforeEndDate(): string {
        return this.state.blockedDays[this.state.focusedInput].slice().reverse().find((el) => {
            return el < toDateString(this.state.endDate);
        });
    }
}
